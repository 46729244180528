<template>
  <div class="policy m-auto px-4" id="top">
    <GoBackBtn class="back" size="40" />

    <img src="@/assets/pocketyard_logo_black.svg" width="130px" class="d-block m-auto" />

    <section id="privacy-policy">
      <h2 class="title mt-2 mb-4" id="section2">Privacy Policy</h2>
      <div class="mb-4">
        <p class="fs-8 mb-2">
          This Privacy Policy describes Our policies and procedures on the collection, use and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You.
        </p>
        <p class="fs-8 mb-2">
          We use Your Personal data to provide and improve the Service. By using the Service, You
          agree to the collection and use of information in accordance with this Privacy Policy.
        </p>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Interpretation and Definitions</h3>
        <h5 class="fs-10 mb-2">Interpretation</h5>
        <p class="fs-8 m-2">
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </p>
      </div>
      <div class="mb-4">
        <h5 class="fs-10 mb-2">Definitions</h5>
        <p class="fs-8 m-0">
          For the purposes of this Privacy Policy:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            <b>Account</b> means a unique account created for You to access our Service or parts of
            our Service.
          </li>
          <li class="my-1">
            <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this
            Agreement) refers to Pocket Yard, 1435 Wazee St, Ste 501, Denver, CO 80202" under
            "Company.
          </li>
          <li class="my-1">
            <b>Cookies</b> are small files that are placed on Your computer, mobile device or any
            other device by a website, containing the details of Your browsing history on that website
            among its many uses.
          </li>
          <li class="my-1"><b>Country</b> refers to: Colorado, United States</li>
          <li class="my-1">
            <b>Device</b> means any device that can access the Service such as a computer, a cellphone
            or a digital tablet.
          </li>
          <li class="my-1">
            <b>Personal Data</b> is any information that relates to an identified or identifiable
            individual.
          </li>
          <li class="my-1"><b>Service</b> refers to the Website.</li>
          <li class="my-1">
            <b>Service Provider</b> means any natural or legal person who processes the data on behalf
            of the Company. It refers to third-party companies or individuals employed by the Company
            to facilitate the Service, to provide the Service on behalf of the Company, to perform
            services related to the Service or to assist the Company in analyzing how the Service is
            used.
          </li>
          <li class="my-1">
            <b>Third-party Social Media Service</b> refers to any website or any social network
            website through which a User can log in or create an account to use the Service.
          </li>
          <li class="my-1">
            <b>Usage Data</b> refers to data collected automatically, either generated by the use of
            the Service or from the Service infrastructure itself (for example, the duration of a page
            visit).
          </li>
          <li class="my-1">
            <b>Website</b> refers to Pocket Yard, accessible from
            <a href="https://pocketyard.com">https://pocketyard.com</a>
          </li>
          <li class="my-1">
            <b>You</b> means the individual accessing or using the Service, or the company, or other
            legal entity on behalf of which such individual is accessing or using the Service, as
            applicable.
          </li>
        </ul>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Collecting and Using Your Personal Data</h3>
        <h4 class="fs-12 mb-2">Types of Data Collected</h4>
        <h5 class="fs-10 mb-2">Personal Data</h5>
        <p class="fs-8 m-2">
          While using Our Service, We may ask You to provide Us with certain personally identifiable
          information that can be used to contact or identify You. Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            Email address
          </li>
          <li class="my-1">
            First name and last name
          </li>
          <li class="my-1">
            Usage Data
          </li>
        </ul>
        <h5 class="fs-10 mb-2">Usage Data</h5>
        <p class="fs-8 m-2">
          Usage Data is collected automatically when using the Service.
        </p>
        <p class="fs-8 m-2">
          Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
          address), browser type, browser version, the pages of our Service that You visit, the time
          and date of Your visit, the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p class="fs-8 m-2">
          When You access the Service by or through a mobile device, We may collect certain
          information automatically, including, but not limited to, the type of mobile device You use,
          Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating
          system, the type of mobile Internet browser You use, unique device identifiers and other
          diagnostic data.
        </p>
        <p class="fs-8 m-2">
          We may also collect information that Your browser sends whenever You visit our Service or
          when You access the Service by or through a mobile device.
        </p>
        <h5 class="fs-10 mb-2">Tracking Technologies and Cookies</h5>
        <p class="fs-8 m-2">
          We use Cookies and similar tracking technologies to track the activity on Our Service and
          store certain information. Tracking technologies used are beacons, tags, and scripts to
          collect and track information and to improve and analyze Our Service.
        </p>
        <p class="fs-8 m-2">
          You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being
          sent. However, if You do not accept Cookies, You may not be able to use some parts of our
          Service.
        </p>
        <p class="fs-8 m-2">
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal
          computer or mobile device when You go offline, while Session Cookies are deleted as soon as
          You close your web browser. Learn more about cookies: All About Cookies.
        </p>
        <p class="fs-8 m-2">
          We use both session and persistent Cookies for the purposes set out below:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            <p class="mb-2 bold"><b>Necessary / Essential Cookies</b></p>
            <p class="mb-2">Type: Session Cookies</p>
            <p class="mb-2">Administered by: Us</p>
            <p class="mb-2">
              Purpose: These Cookies are essential to provide You with services available through the
              Website and to enable You to use some of its features. They help to authenticate users
              and prevent fraudulent use of user accounts. Without these Cookies, the services that
              You have asked for cannot be provided, and We only use these Cookies to provide You with
              those services.
            </p>
          </li>
          <li class="my-1">
            <p class="mb-2"><b>Cookies Policy / Notice Acceptance Cookies</b></p>
            <p class="mb-2">Type: Session Cookies</p>
            <p class="mb-2">Administered by: Us</p>
            <p class="mb-2">
              Purpose: These Cookies identify if users have accepted the use of cookies on the
              Website.
            </p>
          </li>
          <li class="my-1">
            <p class="mb-2"><b>Functionality Cookies</b></p>
            <p class="mb-2">Type: Session Cookies</p>
            <p class="mb-2">Administered by: Us</p>
            <p class="mb-2">
              Purpose: These Cookies allow us to remember choices You make when You use the Website,
              such as remembering your login details or language preference. The purpose of these
              Cookies is to provide You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
        </ul>
        <p class="fs-8 m-2">
          For more information about the cookies we use and your choices regarding cookies, please
          visit our Cookies Policy or the Cookies section of our Privacy Policy.
        </p>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Use of Your Personal Data</h3>
        <p class="fs-8 m-2">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            <b>To provide and maintain our Service</b>, including to monitor the usage of our Service.
          </li>
          <li class="my-1">
            <b>To manage Your Account:</b> to manage Your registration as a user of the Service. The
            Personal Data You provide can give You access to different functionalities of the Service
            that are available to You as a registered user.
          </li>
          <li class="my-1">
            <b>For the performance of a contract:</b> the development, compliance and undertaking of
            the purchase contract for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </li>
          <li class="my-1">
            <b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's push notifications
            regarding updates or informative communications related to the functionalities, products
            or contracted services, including the security updates, when necessary or reasonable for
            their implementation.
          </li>
          <li class="my-1">
            <b>To provide You</b> with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that you have already
            purchased or enquired about unless You have opted not to receive such information.
          </li>
          <li class="my-1">
            <b>To manage Your requests:</b> To attend and manage Your requests to Us.
          </li>
        </ul>
        <p class="fs-8 m-2">
          We may share your personal information in the following situations:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            <b>With Service Providers:</b> We may share Your personal information with Service
            Providers to monitor and analyze the use of our Service, to contact You.
          </li>
          <li class="my-1">
            <b>For Business transfers:</b> We may share or transfer Your personal information in
            connection with, or during negotiations of, any merger, sale of Company assets, financing,
            or acquisition of all or a portion of our business to another company.
          </li>
          <li class="my-1">
            <b>With Affiliates:</b> We may share Your information with Our affiliates, in which case
            we will require those affiliates to honor this Privacy Policy. Affiliates include Our
            parent company and any other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </li>
          <li class="my-1">
            <b>With Business partners:</b> We may share Your information with Our business partners to
            offer You certain products, services or promotions.
          </li>
          <li class="my-1">
            <b>With other users:</b> when You share personal information or otherwise interact in the
            public areas with other users, such information may be viewed by all users and may be
            publicly distributed outside. If You interact with other users or register through a
            Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service
            may see Your name, profile, pictures and description of Your activity. Similarly, other
            users will be able to view descriptions of Your activity, communicate with You and view
            Your profile.
          </li>
        </ul>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Retention of Your Personal Data</h3>
        <p class="fs-8 m-2">
          The Company will retain Your Personal Data only for as long as is necessary for the purposes
          set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
          necessary to comply with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </p>
        <p class="fs-8 m-2">
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data is used to strengthen
          the security or to improve the functionality of Our Service, or We are legally obligated to
          retain this data for longer time periods.
        </p>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Transfer of Your Personal Data</h3>
        <p class="fs-8 m-2">
          Your information, including Personal Data, is processed at the Company's operating offices
          and in any other places where the parties involved in the processing are located. It means
          that this information may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction where the data
          protection laws may differ than those from Your jurisdiction.
        </p>
        <p class="fs-8 m-2">
          Your consent to this Privacy Policy followed by Your submission of such information
          represents Your agreement to that transfer.
        </p>
        <p class="fs-8 m-2">
          The Company will take all steps reasonably necessary to ensure that Your data is treated
          securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
          will take place to an organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Disclosure of Your Personal Data</h3>
        <h5 class="fs-10 mb-2">Business Transactions</h5>
        <p class="fs-8 m-2">
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
          transferred. We will provide notice before Your Personal Data is transferred and becomes
          subject to a different Privacy Policy.
        </p>
        <h5 class="fs-10 mb-2">Law enforcement</h5>
        <p class="fs-8 m-2">
          Under certain circumstances, the Company may be required to disclose Your Personal Data if
          required to do so by law or in response to valid requests by public authorities (e.g. a
          court or a government agency).
        </p>
        <h5 class="fs-10 mb-2">Other legal requirements</h5>
        <p class="fs-8 m-2">
          The Company may disclose Your Personal Data in the good faith belief that such action is
          necessary to:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            Comply with a legal obligation
          </li>
          <li class="my-1">
            Protect and defend the rights or property of the Company
          </li>
          <li class="my-1">
            Prevent or investigate possible wrongdoing in connection with the Service
          </li>
          <li class="my-1">
            Protect the personal safety of Users of the Service or the public
          </li>
          <li class="my-1">
            Protect against legal liability
          </li>
        </ul>
      </div>
      <div class="mb-4">
        <h3 class="fs-13 mb-2">Security of Your Personal Data</h3>
        <p class="fs-8 m-2">
          The security of Your Personal Data is important to Us, but remember that no method of
          transmission over the Internet, or method of electronic storage is 100% secure. While We
          strive to use commercially acceptable means to protect Your Personal Data, We cannot
          guarantee its absolute security.
        </p>
      </div>
      <div class="mb-4">
        <h2 class="mb-2">
          Your California Privacy Rights (California's Shine the Light law)
        </h2>
        <p class="fs-8 m-2">
          Under California Civil Code Section 1798 (California's Shine the Light law), California
          residents with an established business relationship with us can request information once a
          year about sharing their Personal Data with third parties for the third parties' direct
          marketing purposes.
        </p>
        <p class="fs-8 m-2">
          If you'd like to request more information under the California Shine the Light law, and if
          you are a California resident, You can contact Us using the contact information provided
          below.
        </p>
      </div>
      <div class="mb-4">
        <h2 class="mb-2">
          California Privacy Rights for Minor Users (California Business and Professions Code Section
          22581)
        </h2>
        <p class="fs-8 m-2">
          California Business and Professions Code section 22581 allow California residents under the
          age of 18 who are registered users of online sites, services or applications to request and
          obtain removal of content or information they have publicly posted.
        </p>
        <p class="fs-8 m-2">
          To request removal of such data, and if you are a California resident, You can contact Us
          using the contact information provided below, and include the email address associated with
          Your account.
        </p>
        <p class="fs-8 m-2">
          Be aware that Your request does not guarantee complete or comprehensive removal of content
          or information posted online and that the law may not permit or require removal in certain
          circumstances.
        </p>
      </div>
      <div class="mb-4">
        <h2 class="mb-2">
          Links to Other Websites
        </h2>
        <p class="fs-8 m-2">
          Our Service may contain links to other websites that are not operated by Us. If You click on
          a third party link, You will be directed to that third party's site. We strongly advise You
          to review the Privacy Policy of every site You visit.
        </p>
        <p class="fs-8 m-2">
          We have no control over and assume no responsibility for the content, privacy policies or
          practices of any third party sites or services.
        </p>
      </div>
      <div class="mb-4">
        <h2 class="mb-2">
          Changes to this Privacy Policy
        </h2>
        <p class="fs-8 m-2">
          We may update our Privacy Policy from time to time. We will notify You of any changes by
          posting the new Privacy Policy on this page.
        </p>
        <p class="fs-8 m-2">
          We will let You know via email and/or a prominent notice on Our Service, prior to the change
          becoming effective and update the "Last updated" date at the top of this Privacy Policy.
        </p>
        <p class="fs-8 m-2">
          You are advised to review this Privacy Policy periodically for any changes. Changes to this
          Privacy Policy are effective when they are posted on this page.
        </p>
      </div>
      <div class="mb-4">
        <h2 class="mb-2">
          Contact Us
        </h2>
        <p class="fs-8 m-2">
          If you have any questions about this Privacy Policy, You can contact us:
        </p>
        <ul class="fs-8">
          <li class="my-1">
            By email:
            <b><a href="mailto:info@pocketyard.com">info@pocketyard.com</a></b>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import GoBackBtn from '@/components/Partial/GoBackBtn';

export default {
  name: 'PrivacyPolicy',

  components: {
    GoBackBtn,
  },
  mounted() {
    document.getElementById('top').scrollIntoView({ behavior: 'smooth' })
  }
};
</script>

<style lang="scss" scoped>
.policy {
  max-width: 1000px;

  & .back {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
</style>
